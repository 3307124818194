/* eslint-disable @typescript-eslint/no-explicit-any */
export const registrations = [
    {
        name: 'AssetList',
        import: () => import('@stories/Widgets/AssetListing/AssetListing'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'Accordion',
        import: () => import('@stories/Widgets/Accordion/Accordion'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'BlockQuote',
        import: () => import('@stories/Widgets/BlockQuote/BlockQuote'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ContactUsForm',
        import: () => import('@stories/Widgets/ContactUsForm/ContactUsForm'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ExpertiseList',
        import: () => import('@stories/Widgets/ExpertiseListing/ExpertiseListing'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'Footer',
        import: () => import('@stories/Widgets/Footer/Footer'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'Header',
        import: () => import('@stories/Widgets/Header/Header'),
        config: {
            hydrate: 'always',
        },
    },
    {
        name: 'HeroBanner',
        import: () => import('@stories/Widgets/HeroBanner/HeroBanner'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'HeroInner',
        import: () => import('@stories/Widgets/HeroInner/HeroInner'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'HeroLanding',
        import: () => import('@stories/Widgets/HeroLanding/HeroLanding'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'HeroNews',
        import: () => import('@stories/Widgets/HeroNews/HeroNews'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'Image',
        import: () => import('@stories/Widgets/Image/Image'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ImageGallery',
        import: () => import('@stories/Widgets/ImageGallery/ImageGallery'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'Information',
        import: () => import('@stories/Widgets/InformationCard/InformationCard'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'InformationCarousel',
        import: () => import('@stories/Widgets/InformationCarousel/InformationCarousel'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'LogoStrip',
        import: () => import('@stories/Widgets/LogoAccreditations/LogoAccreditations'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'NewsList',
        import: () => import('@stories/Widgets/NewsList/NewsList'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'NewsDetailTags',
        import: () => import('@stories/Widgets/NewsDetailTags/NewsDetailTags'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'PeopleList',
        import: () => import('@stories/Widgets/PeopleList/PeopleList'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'Profile',
        import: () => import('@stories/Widgets/PeopleProfile/PeopleProfile'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'RichText',
        import: () => import('@stories/Widgets/RichText/RichText'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'RelatedAssets',
        import: () => import('@stories/Widgets/RelatedAssets/RelatedAssets'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'RelatedExpertise',
        import: () => import('@stories/Widgets/RelatedExpertise/RelatedExpertise'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'RelatedPeople',
        import: () => import('@stories/Widgets/RelatedPeople/RelatedPeople'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'RelatedNews',
        import: () => import('@stories/Widgets/RelatedNews/RelatedNews'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ShareButtons',
        import: () => import('@stories/Widgets/ShareButtons/ShareButtons'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'SiteMap',
        import: () => import('@stories/Widgets/SiteMap/SiteMap'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'SkipToContent',
        import: () => import('@stories/Widgets/SkipToContent/SkipToContent'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'SpaceFeaturesStats',
        import: () => import('@stories/Widgets/SpaceFeaturesStats/SpaceFeaturesStats'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'TestimonialQuote',
        import: () => import('@stories/Widgets/TestimonialQuote/TestimonialQuote'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'Video',
        import: () => import('@stories/Widgets/Video/Video'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'DownloadList',
        import: () => import('@stories/Widgets/DownloadList/DownloadList'),
        config: {
            hydrate: 'in-view',
        },
    },
];
